<template>
  <div>
    <b-button variant="danger" pill size="sm"
              @click="openModal()">
      <b-icon icon="stopwatch" aria-hidden="true"/>
    </b-button>

    <b-modal :title="`Servicios de Transporte con Exceso de Permanencia ${serviceId}`"
             ref="modal-form" size="xl" @hide="closeModal">

      <h5 class="text-center">Servicios de Transporte con Motivo de Exceso de Permanencia</h5>
      <hr class="bg-primary">

      <a-table
        size="small"
        :scroll="{x:1300}"
        bordered
        :dataSource="tableData.data"
        :columns="columns"
        :loading="tableData.loading"
        :row-key="record => record.jsonApi.id"
        :pagination="tableData.pagination"
        @change="getExcessTimeReasons"
      >
        <div slot="servicePlanningsCarriers" slot-scope="data">
          <strong>Placa: </strong>{{data.trucks.tag}}
          <br>
          <template v-if="data.trucks.carrierCompanies.sct_record">
            <strong>Registro SCT: </strong>{{data.trucks.carrierCompanies.sct_record}}
            <br>
          </template>
          <template v-if="data.trucks.carrierCompanies.environmental_record">
            <strong>Autorización SEMARNAT: </strong>{{data.trucks.carrierCompanies.environmental_record}}
            <br>
          </template>
          <strong>Tipo Logístico de Transporte: </strong> {{data.trucks.logisticsShipmentTypes.name}}
          <br>
          <strong>Acoplados: </strong>{{data.trucks.logisticsShipmentTypes.trailers}}
          <br>
          <strong>Conductor: </strong>{{data.drivers.name}}
        </div>
        <div slot="excessTimeInPlants" slot-scope="data">
          {{data.name}}
        </div>
      </a-table>

      <h5 class="text-center">Servicios de Transporte con Exceso de Permanencia</h5>
      <hr class="bg-primary">

      <servicePlanningCarrierTable v-if="selectedCarrier === null"
                                    :serviceId="serviceId" :tableObject="tableCarrier"
                                   :view="['select']" @SelectCarrier="selectCarrier"
                                   :excessPermanence="{ value: 1 }" />

      <template v-else>
        <a-form-model ref="rule-Form" :model="form" :rules="rules" layout="vertical">
          <a-form-model-item ref="name" label="Motivo de Exceso de Permanencia" prop="country" >
            <a-select v-model="form.excess_time_in_plant_id"
                      placeholder="Seleccionar Motivo"
                      show-search
                      :filter-option="filterOption"
            >
              <a-select-option  v-for="option in excessTimeInPlants"
                                :key="option.jsonApi.id" :value="option.jsonApi.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item ref="name" label="Comentarios" prop="name" >
            <a-input v-model.trim="form.comments" type="textarea" :rows="4"/>
          </a-form-model-item>
          <div class="row justify-content-center" v-show="!formLoad">
            <responsive-button variant="primary" pill size="sm" text="Guardar" responsive="md" icon="archive-fill" @ClickEvent="onSubmit"/>
          </div>
        </a-form-model>
      </template>

      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="closeModal"/>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { getGeneralMixin } from '@/mixins/getGeneralMixin'
import servicePlanningCarrierTable from './servicePlanningCarrierTable'
import { notification } from 'ant-design-vue'
export default {
  name: 'spCarrierExcessPermanenceForm',
  props: {
    serviceId: {
      type: [String],
      required: true,
    },
  },
  components: {
    servicePlanningCarrierTable,
  },
  mixins: [
    getGeneralMixin,
  ],
  data() {
    return {
      tableCarrier: {
        data: [],
        loading: false,
        perPageValue: 15,
        currentPageValue: 1,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 15,
          lastPage: 0,
        },
      },
      tableData: {
        data: [],
        loading: false,
        perPageValue: 15,
        currentPageValue: 1,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 15,
          lastPage: 0,
        },
      },
      selectedCarrier: null,
      formLoad: false,
      form: {
        service_plannings_carrier_id: undefined,
        excess_time_in_plant_id: undefined,
        comments: '',
      },
      rules: {
        excess_time_in_plant_id: [
          { required: true, message: 'El campo Motivo de Exceso de Permanencia en Planta es obligatorio.', trigger: 'blur' },
        ],
      },
      excessTimeInPlants: [],
      columns: [
        {
          title: 'Servicio de Transporte',
          dataIndex: 'servicePlanningsCarriers',
          key: 'servicePlanningsCarriers',
          width: '150px',
          scopedSlots: {
            customRender: 'servicePlanningsCarriers',
          },
        },
        {
          title: 'Motivo de Exceso de Permanencia',
          dataIndex: 'excessTimeInPlants',
          key: 'excessTimeInPlants',
          width: '100px',
          scopedSlots: {
            customRender: 'excessTimeInPlants',
          },
        },
        {
          title: 'Comentarios',
          dataIndex: 'comments',
          key: 'comments',
          width: '100px',
        },
      ],
    }
  },
  methods: {
    async selectCarrier(data) {
      this.selectedCarrier = data
      this.excessTimeInPlants = await this.getSelectData('excessTimeInPlants', { sort: 'name' })
    },
    async GetCarriers(paginationEvent = {}) {
      const params = {
        'page[number]': (Object.keys(paginationEvent).length !== 0) ? `${this.tableCarrier.pagination.current}` : `${this.tableCarrier.currentPageValue}`,
        'page[size]': `${this.tableCarrier.perPageValue}`,
        'filter[excessPermanence]': '1',
        'filter[service]': this.serviceId,
        include: 'drivers,trucks.logisticsShipmentTypes,trucks.carrierCompanies,weightRegistries,checkListForms.checks',
      }

      await this.getGeneralAntTable(paginationEvent, 'servicePlanningsCarriers', params, this.tableCarrier)
    },
    async getExcessTimeReasons(paginationEvent = {}) {
      const params = {
        'page[number]': (Object.keys(paginationEvent).length !== 0) ? `${this.tableData.pagination.current}` : `${this.tableData.currentPageValue}`,
        'page[size]': `${this.tableData.perPageValue}`,
        'filter[servicePlannings]': this.serviceId,
        include: 'excessTimeInPlants,servicePlanningsCarriers.drivers,servicePlanningsCarriers.trucks.logisticsShipmentTypes,servicePlanningsCarriers.trucks.carrierCompanies',
      }

      await this.getGeneralAntTable(paginationEvent, 'excessTimeReasons', params, this.tableData)
    },
    async openModal() {
      this.$refs['modal-form'].show()
      await this.getExcessTimeReasons()
      await this.GetCarriers()
    },
    closeModal() {
      this.$refs['modal-form'].hide()
      this.selectedCarrier = null
    },
    onSubmit() {
      this.$refs['rule-Form'].validate(valid => {
        if (valid) {
          const relationships = {
            excessTimeInPlants: {
              data: { id: this.form.excess_time_in_plant_id, type: 'excessTimeInPlants' },
            },
            servicePlanningsCarriers: {
              data: { id: this.selectedCarrier.jsonApi.id, type: 'servicePlanningsCarriers' },
            },
          }
          const formObject = {
            comments: this.form.comments,
            jsonApi: {
              type: 'excessTimeReasons',
              relationships: relationships,
            },
          }
          this.load = true
          this.$store.dispatch('jv/post', [formObject, {}])
            .then(async() => {
              notification.success({
                message: 'Registro guardado exitosamente',
              })
              this.selectedCarrier = null
              await this.getExcessTimeReasons()
              await this.GetCarriers()
            })
            .catch(() => {
              console.error('An error has occurred')
              this.load = false
            })
        } else return false
      })
    },
  },
}
</script>

<style scoped>

</style>
