<template>
  <a-table
    :scroll="{ x: 800, y: 1000}"
    :dataSource="tableObject.data"
    :columns="columns"
    :loading="tableObject.loading"
    :row-key="record => record.sap_programming_code"
    :pagination="tableObject.pagination"
    @change="refreshResults"
    :row-selection="rowSelection"
    @expand="expand"
    bordered
  >
    <div slot="filterDropdown"
         slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
         class='custom-filter-dropdown'>
      <a-input
        v-ant-ref="c => searchInput = c"
        :placeholder="`Buscar por ${column.title}`"
        :value="selectedKeys[0]"
        @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
        @pressEnter="() => handleSearch(selectedKeys, confirm)"
        style="width: 188px; margin-bottom: 8px; display: block;"
      />
      <a-button
        type='primary'
        @click="() => handleSearch(selectedKeys, confirm)"
        icon="search"
        size="small"
        style="width: 90px; margin-right: 8px"
      >Buscar</a-button>
      <a-button
        @click="() => handleReset(clearFilters)"
        size="small"
        style="width: 90px"
      >Limpiar</a-button>
    </div>
    <a-icon slot="filterIcon" type='search' slot-scope="filtered" :style="{ color: filtered ? '#108ee9' : undefined }"/>
    <template slot="customRender" slot-scope="text">
        <span v-if="searchText">
          <template v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
            <mark v-if="fragment.toLowerCase() === searchText.toLowerCase()" :key="i" class="highlight">{{fragment}}</mark>
            <template v-else>{{fragment}}</template>
          </template>
        </span>
      <template v-else>{{text}}</template>
    </template>
    <!-- Columnas Personalizadas -->
    <template slot="title">
      <h5 class="text-block text-default mt-2 mb-4 ml-4 text-center">
        {{ tableObject.header }}
      </h5>
    </template>

    <template slot="sap_programming_code" slot-scope="data">
      <div class="row my-0 py-0">
        <div class="col-sm-12 col-lg-4 text-right">
          <strong>Clave: </strong>
        </div>
        <div class="col-sm-12 col-lg-8 text-left">
          <b-button variant="link" @click="GoToServicePlanning(data)" size="sm">
            {{data.jsonApi.id}}
          </b-button>
        </div>
      </div>
      <hr class="bg-primary">
      <div class="row my-0 py-0">
        <div class="col-sm-12 col-lg-4 text-right">
          <strong>SAP: </strong>
        </div>
        <div class="col-sm-12 col-lg-8 text-left">
          <b-button variant="link" @click="GoToServicePlanning(data)" size="sm">
            {{data.sap_programming_code}}
          </b-button>
        </div>
      </div>
      <hr class="bg-primary">
      <serviceDetailModal :serviceId="data.jsonApi.id" />
    </template>
    <div slot="workCenter" slot-scope="data">
      <div class="row my-0 py-0">
        <div class="col-sm-12 col-lg-6 text-right">
          <strong>Planta: </strong>
        </div>
        <div class="col-sm-12 col-lg-6 text-left">
          <span> {{data.workCenters.code}} - {{data.workCenters.name}} </span>
          <br>
        </div>
      </div>
      <hr class="bg-primary">
      <div class="row my-0 py-0">
        <div class="col-sm-12 col-lg-6 text-right">
          <strong>Generador: </strong>
        </div>
        <div class="col-sm-12 col-lg-6 text-left">
          <generatorLogicInfo :service="data"/>

        </div>
      </div>
    </div>

    <div slot="currents" slot-scope="servicePlanningsMaterials">
      <div v-if="Object.keys(servicePlanningsMaterials).length">
        <span v-for="item in servicePlanningsMaterials" :key="item.id">
          <b-icon icon="clipboard-check"></b-icon> {{ item.currents.name }}
        </span>
      </div>
      <span v-else>-</span>
    </div>
    <template slot="spcomercialCarriers" slot-scope="spcomercialCarriers">
<!--      Algo aui {{spcomercialCarriers}}-->
      <ul class="list-group">
        <li v-for="transport in spcomercialCarriers" :key="transport.jsonApi.id" class="list-group-item">
          <div class="row my-0 py-0">
            <div class="col-6 text-right">
              <strong>Tipo : </strong>
            </div>
            <div class="col-6 text-left">
              <span> {{transport.trucks.logisticsShipmentTypes.code}}</span> -
              <span> {{transport.trucks.logisticsShipmentTypes.name}}</span>
<!--              <span> {{transport}}</span>-->
            </div>
          </div>
          <hr class="bg-primary">
          <div class="row my-0 py-0">
            <div class="col-6 text-right">
              <strong>Capacidad : </strong>
            </div>
            <div class="col-6 text-left">
              <span> {{transport.trucks.maximum_capacity}}</span>
            </div>
          </div>
        </li>
      </ul>
    </template>
    <div slot="program_date" slot-scope="servicePlanningsPeriods">
      <div class="row my-0 py-0">
        <div class="col-sm-12 col-lg-6 text-right">
          <strong>Programación: </strong>
        </div>
        <div class="col-sm-12 col-lg-6 text-left">
          <span>{{moment(servicePlanningsPeriods.program_dated_at).format('DD/MM/YYYY')}}</span> - <span>{{servicePlanningsPeriods.program_hour}}</span>
        </div>
      </div>
      <hr class="bg-primary">
      <div class="row my-0 py-0">
        <div class="col-sm-12 col-lg-6 text-right">
          <strong>Llegada Estimada: </strong>
        </div>
        <div class="col-sm-12 col-lg-6 text-left">
          <span>{{moment(servicePlanningsPeriods.coming_estimated_at).format('DD/MM/YYYY')}}</span> - <span>{{servicePlanningsPeriods.coming_estimated_hour}}</span>
        </div>
      </div>
      <hr class="bg-primary">
      <div class="row my-0 py-0">
        <div class="col-sm-12 col-lg-6 text-right">
          <strong>Recolección: </strong>
        </div>
        <div class="col-sm-12 col-lg-6 text-left">
          <span>{{moment(servicePlanningsPeriods.pickup_dated_at).format('DD/MM/YYYY')}}</span> - <span>{{servicePlanningsPeriods.pickup_hour}}</span>
        </div>
      </div>
    </div>
    <div slot="details" slot-scope="data">
      <template id="stage">
        <div class="row px-5 mb-2 pt-1 bg-primary rounded">
          <div class="col-sm-12 col-lg-6 text-right">
            <span class="text-white font-weight-bold font-italic font-size-18">Etapa: </span>
          </div>
          <div class="col-sm-12 col-lg-6 text-left">
            <span class="text-secondary font-weight-bold font-italic font-size-16">{{data.lastSubstage}}</span>
          </div>
        </div>
      </template>
      <template id="tipo_servicio">
        <div v-if="Object.keys(data.serviceTypes).length" class="row my-0 py-0">
          <div class="col-6 text-right">
            <strong>Tipo de Servicio: </strong>
          </div>
          <div class="col-6 text-left">
            <span v-if="data.serviceTypes.name === 'Entrada por Venta' || data.serviceTypes.name === 'Entrada por Compra'" class="text-primary">
              <b-icon icon="door-open-fill"></b-icon>
              <small>{{data.serviceTypes.name}}</small>
            </span>
              <span v-else-if="data.serviceTypes.name === 'Salida por Disposición'" class="text-danger">
              <b-icon icon="door-closed-fill"></b-icon>
              <small>{{data.serviceTypes.name}}</small>
            </span>
              <span v-else-if="data.serviceTypes.name === 'Salida por Traspaso'" class="text-danger">
              <b-icon icon="arrow-left-right"></b-icon>
              <small>{{data.serviceTypes.name}}</small>
            </span>
              <span v-else-if="data.serviceTypes.name === 'Entrada por Traspaso'" class="text-warning">
              <b-icon icon="arrow-left-right"></b-icon>
              <small>{{data.serviceTypes.name}}</small>
            </span>
          </div>
          <hr class="bg-dark">
        </div>
      </template>
      <template id="status">
        <div class="row my-0 py-0">
          <div class="col-6 text-right">
            <strong>Estatus: </strong>
          </div>
          <div class="col-6 text-left">
            <span class="text-primary" v-if="data.status === 'Programado'">
              <b-icon icon="check"/> Programado
            </span>
            <span class="text-warning" v-else>
              <b-icon icon="file-earmark-x-fill"/> No Programado
            </span>
            <br>
          </div>
          <hr class="bg-dark">
        </div>
        <div class="row my-0 py-0">
          <div class="col-6 text-right">
            <strong>Estatus SAP: </strong>
          </div>
          <div class="col-6 text-left">
            <b-iconstack font-scale="1.5" variant="warning" v-if="data.sap_status === 'Pendiente'" :title="data.sap_status" animation="cylon">
              <b-icon stacked icon="question"  scale="0.5" shift-v="-2"/>
              <b-icon stacked icon="calendar" />
            </b-iconstack>
            <b-iconstack font-scale="1.5" variant="danger" v-else-if="data.sap_status === 'Rechazado'" :title="data.sap_status" animation="cylon">
              <b-icon stacked icon="file-earmark-x-fill"  scale="0.5" shift-v="-2"/>
              <b-icon stacked icon="calendar" />
            </b-iconstack>
            <b-iconstack font-scale="1.5" variant="primary" v-else-if="data.sap_status === 'Programado'" :title="data.sap_status" animation="cylon">
              <b-icon stacked icon="check"  scale="0.5" shift-v="-2"/>
              <b-icon stacked icon="calendar" />
            </b-iconstack>
          </div>
        </div>
      </template>
      <template id="tipo_logistico_turno">
        <div class="row mt-2">
          <div class="col-6 text-right">
            <strong>Turno: </strong>
          </div>
          <div class="col-6 text-left">
            <span v-if="data.shifts">{{data.shifts.name}}</span>
          </div>
        </div>
        <div class="row my-0 py-0 mt-2">
          <div class="col-6 text-right">
            <strong>Transporte: </strong>
          </div>
          <div class="col-6 text-left">
            <span v-if="data.own" class="text-dark font-weight-bold"> Geocycle</span>
            <span v-else class="text-warning font-weight-bold"> Del Cliente</span>
          </div>
        </div>
      </template>
    </div>
    <!-- COLUMNAS CONDICONALES -->
    <div  slot="excessPermanence" slot-scope="data"
          v-if="conditionalColumns.includes('excessPermanence')">

      <spCarrierExcessPermanenceForm  v-if="data.excessPermanence" :serviceId="data.jsonApi.id"/>
    </div>
    <a slot="action" slot-scope="data">
      <b-button-group>
        <b-button variant="primary" pill size="sm" title="Editar" @click="GoToServicePlanning(data)">
          <b-icon icon="pencil" aria-hidden="true"/>
        </b-button>
        <serviceDetailModal :serviceId="data.jsonApi.id" />
      </b-button-group>
    </a>
  </a-table>
</template>

<script>
import spCarrierExcessPermanenceForm from './spCarrierExcessPermanenceForm'
import serviceDetailModal from './serviceDetailModal'
import generatorLogicInfo from '@/views/geocycle/service_plannings/components/generatorLogicInfo'
export default {
  name: 'servicesTable',
  props: {
    tableObject: Object,
    conditionalColumns: {
      required: false,
      default: () => {
        return []
      },
      type: [Array],
    },
  },
  components: {
    spCarrierExcessPermanenceForm,
    serviceDetailModal,
    generatorLogicInfo,
  },
  data () {
    return {
      // Varialbles propias para el funcionamiento de la tabla
      searchText: '',
      searchInput: null,
      // Varialbles propias para el funcionamiento de la tabla
      defaultColumns: [
        {
          title: 'Servicio',
          dataIndex: '',
          key: 'sap_programming_code',
          align: 'center',
          width: '10%',
          class: 'small',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'sap_programming_code',
          },
          onFilter: (value, record) => record.sap_programming_code.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Planta/Generador',
          dataIndex: '',
          key: 'work_centers',
          align: 'center',
          width: '10%',
          class: 'small',
          scopedSlots: {
            customRender: 'workCenter',
          },
        },
        {
          title: 'Corrientes',
          dataIndex: 'servicePlanningsMaterials',
          key: 'currents',
          align: 'center',
          width: '7%',
          class: 'small',
          scopedSlots: {
            customRender: 'currents',
          },
        },
        {
          title: 'Transportes',
          dataIndex: 'servicePlanningsCarriers',
          key: 'servicePlanningsCarriers',
          align: 'center',
          width: '10%',
          class: 'small',
          scopedSlots: {
            customRender: 'spcomercialCarriers',
          },
        },
        {
          title: 'Fechas',
          dataIndex: 'servicePlanningsPeriods',
          key: 'program_date',
          align: 'center',
          width: '10%',
          class: 'small',
          scopedSlots: {
            customRender: 'program_date',
          },
        },
        {
          title: 'Detalles',
          dataIndex: '',
          key: 'details',
          align: 'center',
          width: '15%',
          class: 'small',
          scopedSlots: {
            customRender: 'details',
          },
        },
      ],
      columns: [],
    }
  },
  watch: {
    searchInput(newValue) {
      this.$emit('searchInput', newValue)
    },
    conditionalColumns(newValue) {
      if (newValue.includes('excessPermanence')) {
        this.columns = [
          {
            title: 'No. Programación SAP',
            dataIndex: '',
            key: 'sap_programming_code',
            align: 'center',
            width: '7%',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'sap_programming_code',
            },
            onFilter: (value, record) => record.sap_programming_code.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Planta/Generador',
            dataIndex: '',
            key: 'work_centers',
            align: 'center',
            width: '10%',
            class: 'small',
            scopedSlots: {
              customRender: 'workCenter',
            },
          },
          {
            title: 'Corrientes',
            dataIndex: 'servicePlanningsMaterials',
            key: 'currents',
            align: 'center',
            width: '7%',
            class: 'small',
            scopedSlots: {
              customRender: 'currents',
            },
          },
          {
            title: 'Transportes',
            dataIndex: 'spcomercialCarriers',
            key: 'spcomercialCarriers',
            align: 'center',
            width: '10%',
            class: 'small',
            scopedSlots: {
              customRender: 'spcomercialCarriers',
            },
          },
          {
            title: 'Fechas',
            dataIndex: 'servicePlanningsPeriods',
            key: 'program_date',
            align: 'center',
            width: '10%',
            class: 'small',
            scopedSlots: {
              customRender: 'program_date',
            },
          },
          {
            title: 'Detalles',
            dataIndex: '',
            key: 'details',
            align: 'center',
            width: '15%',
            class: 'small',
            scopedSlots: {
              customRender: 'details',
            },
          },
          {
            title: 'Exceso de Permanencia',
            dataIndex: '',
            key: 'excessPermanence',
            align: 'center',
            width: 'auto',
            class: 'small',
            scopedSlots: {
              customRender: 'excessPermanence',
            },
          }]
      } else this.columns = this.defaultColumns
    },
  },
  computed: {
    rowSelection() {
      return {
        type: 'radio',
        columnWidth: '2%',
        // columnTitle: 'S',
        onChange: (selectedRowKeys, selectedRows) => {
          this.$emit('selectedService', selectedRowKeys[0])
        },
      }
    },
  },
  methods: {
    GoToServicePlanning(data) {
      this.$store.dispatch('servicePlannings/UPDATE_FOLIO', { payload: data.sap_programming_code })
      this.$store.dispatch('servicePlannings/UPDATE_KEY', { payload: data.jsonApi.id })
      this.$router.push({ name: 'service', params: { folio: data.sap_programming_code, id: data.jsonApi.id } })
    },
    expand(expanded, record) {
      // console.log(record)
    },
    handleSearch (selectedKeys, confirm) {
      confirm()
      this.searchText = selectedKeys[0]
    },
    handleReset (clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    excessPermanence(data) {
      this.$emit('excessPermanenceFunc', data)
    },
    refreshResults(paginationEvent = {}) {
      this.$emit('Filter', paginationEvent)
    },
  },
  mounted() {
    this.columns = this.defaultColumns
  },
}
</script>

<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
