<template>
<div>
  <b-button v-if="buttonType ==='table'"
            variant="secondary"
            pill size="sm" title="Ver Detalle" @click="openModal()">
    <b-icon icon="eyeglasses" aria-hidden="true"/>
  </b-button>

  <responsive-button v-if="buttonType ==='stage'"
                    pill class="mt-2 mx-1 bg-real-blue"
                    text="Etapas de Proceso" @ClickEvent="openModal()"
                     responsive="lg" icon="bar-chart-steps" size="sm"/>

  <b-modal title="Información del Servicio" centered
           ref="modal-Info" size="xl" @hide="closeModal"
  >
    <b-container fluid>
      <!--Tabla -->
      <template v-if="showTable">
        <div class="clearfix">
          <h5 class="text-capitalize text-secondary border-bottom">Información general del servicio: {{serviceId}}</h5>
        </div>
        <div class="row my-2">
          <div class="container">
            <div class="border-bottom border-top border-primary">
              <b-table :items="items" :fields="columns" :busy="isBusy" stacked="xl" outlined responsive="lg">
                <template #cell(show_details)="row">
                  <b-button size="sm" pill @click="row.toggleDetails" class="mr-2">
                    {{ row.detailsShowing ? '-' : '+'}}
                  </b-button>
                </template>
                <template #thead-top>
                  <b-tr>
                    <b-th class="text-center text-dark" variant="success" :colspan="columns.length">
                      Información General del Servicio
                    </b-th>
                  </b-tr>
                </template>
                <template #cell(servicePlanningsPeriods)="data">
                  {{ moment(data.value.program_dated_at).format('MM/DD/YYYY') }} {{data.value.program_hour}}
                </template>
                <template #cell(workCenters)="data">
                  {{data.value.name}}
                </template>
                <template #cell(serviceTypes)="data">
                  {{data.value.name}}
                </template>
                <template #cell(servicePlanningsMaterials)="data">
                  <div v-if="Object.keys(data.value).length">
                    <span v-for="item in data.value" :key="item.id">
                      <b-icon icon="clipboard-check"/> {{ item.currents.name }}
                    </span>
                  </div>
                  <span v-else>  -  </span>
                </template>
                <template #cell(excessPermanence)="data">
                  <spCarrierExcessPermanenceForm  v-if="data.value" :serviceId="serviceId"/>
                </template>
                <template #cell(actions)="data">
                  <b-button-group>
                    <b-button variant="primary" pill size="sm" title="Editar" @click="GoToServicePlanning(data)">
                      <b-icon icon="pencil" aria-hidden="true"/>
                    </b-button>
<!--                    TODO: Boton para ver evidencias-->
<!--                    <b-button variant="warning" pill size="sm" title="Evidencia">-->
<!--                      <b-icon icon="camera" aria-hidden="true"/>-->
<!--                    </b-button>-->
                  </b-button-group>
                </template>

                <template #row-details="row">
                  <b-card>
                    <b-table :items="fromObjectToArray(row.item.servicePlanningsMaterials)" :fields="materialColumns"
                             striped hover responsive="lg" stacked="xl">
                      <template #thead-top>
                        <b-tr>
                          <b-th class="text-center text-dark" variant="success" :colspan="materialColumns.length">
                            Corrientes
                          </b-th>
                        </b-tr>
                      </template>
                      <template #cell(currents)="data">
                        {{data.value.name}}
                      </template>
                      <template #cell(containerTypes)="containerTypes">
                        {{containerTypes.value.name}}
                      </template>
                      <template #cell(total)="data">
                        {{data.item.container_capacity * data.item.container_quantity}}
                      </template>
                      <template #cell(ums)="data">
                        {{data.value.name}}
                      </template>
                      <template #cell(disposalTypes)="disposalTypes">
                        {{disposalTypes.value.name}}
                      </template>
                    </b-table>
                  </b-card>
                </template>

                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Cargando...</strong>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </template>
      <!--Tabla -->
      <!--Porcentaje de avances -->
      <div class="row mt-5">
        <div class="container">
<!--          <a-tabs :defaultActiveKey="defaultActiveKey"  type="line" size="large" tabPosition="left">-->
<!--            <a-tab-pane :disabled="!showOtherTabs" key="1">-->
<!--              <div slot="tab">-->
<!--                <div class="container">-->
<!--                  <small class="text-center text-block text-white bg-primary rounded px-2 py-1">Generación del servicio</small>-->
<!--                  <br>-->
<!--                  <a-progress :percent="50" size="small" strokeColor="#1890FF" />-->
<!--                </div>-->
<!--              </div>-->
<!--                <div class="card">-->
<!--                  <div class="container">-->
<!--                    <b-table-simple hover stacked="xl" responsive="lg">-->
<!--                      <b-thead head-variant="light">-->
<!--                        <b-tr>-->
<!--                          <b-th  class="text-center"><small>Etapa</small></b-th>-->
<!--                          <b-th  class="text-center"><small>Progreso</small></b-th>-->
<!--                          <b-th  class="text-center"><small>Estatus</small></b-th>-->
<!--                        </b-tr>-->
<!--                      </b-thead>-->
<!--                      <b-tbody>-->
<!--                        <b-tr>-->
<!--                          <b-td  class="text-center">General </b-td>-->
<!--                          <b-td  class="text-center">-->
<!--                            <a-progress :percent="100" size="small" status="active" />-->
<!--                          </b-td>-->
<!--                          <b-td  class="text-center">-->
<!--                            <b-icon icon="check" variant="success"/> Concluido-->
<!--                          </b-td>-->
<!--                        </b-tr>-->
<!--                        <b-tr>-->
<!--                          <b-td  class="text-center">Transporte </b-td>-->
<!--                          <b-td  class="text-center">-->
<!--                            <a-progress :percent="30" size="small" status="active" />-->
<!--                          </b-td>-->
<!--                          <b-td  class="text-center">-->
<!--                            <b-icon icon="alarm-fill" variant="secondary" strokeColor="#f5e68f"/> En Proceso-->
<!--                          </b-td>-->
<!--                        </b-tr>-->
<!--                        <b-tr>-->
<!--                          <b-td  class="text-center">Extra </b-td>-->
<!--                          <b-td  class="text-center">-->
<!--                            <a-progress :percent="0" size="small" status="active" />-->
<!--                          </b-td>-->
<!--                          <b-td  class="text-center">-->
<!--                            <b-icon icon="clock-history" variant="warning"/> En Espera-->
<!--                          </b-td>-->
<!--                        </b-tr>-->
<!--                        <b-tr>-->
<!--                          <b-td  class="text-center">Residuos </b-td>-->
<!--                          <b-td  class="text-center">-->
<!--                            <a-progress :percent="0" size="small" status="active" />-->
<!--                          </b-td>-->
<!--                          <b-td  class="text-center">-->
<!--                            <b-icon icon="clock-history" variant="warning"/> En Espera-->
<!--                          </b-td>-->
<!--                        </b-tr>-->
<!--                      </b-tbody>-->
<!--                    </b-table-simple>-->
<!--                  </div>-->
<!--                </div>-->
<!--            </a-tab-pane>-->
<!--            <a-tab-pane :disabled="!showOtherTabs" key="2">-->
<!--              <div slot="tab">-->
<!--                <div class="container">-->
<!--                  <small class="text-center text-block text-white bg-primary rounded px-2 py-1">Recepción del servicio</small>-->
<!--                  <br>-->
<!--                  <a-progress :percent="20" status="active" size="small" strokeColor="#1890FF"/>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="card">-->
<!--                <div class="container">-->
<!--                  <b-table-simple hover stacked="xl" responsive="lg">-->
<!--                    <b-thead head-variant="light">-->
<!--                      <b-tr>-->
<!--                        <b-th  class="text-center"><small>Etapa</small></b-th>-->
<!--                        <b-th  class="text-center"><small>Progreso</small></b-th>-->
<!--                        <b-th  class="text-center"><small>Estatus</small></b-th>-->
<!--                      </b-tr>-->
<!--                    </b-thead>-->
<!--                    <b-tbody>-->
<!--                      <b-tr>-->
<!--                        <b-td  class="text-center">Vigilancia </b-td>-->
<!--                        <b-td  class="text-center">-->
<!--                          <a-progress :percent="0" size="small" status="active" />-->
<!--                        </b-td>-->
<!--                        <b-td  class="text-center">-->
<!--                          <b-icon icon="clock-history" variant="warning"/> En Espera-->
<!--                        </b-td>-->
<!--                      </b-tr>-->
<!--                      <b-tr>-->
<!--                        <b-td  class="text-center">Laboratorio </b-td>-->
<!--                        <b-td  class="text-center">-->
<!--                          <a-progress :percent="0" size="small" status="active" />-->
<!--                        </b-td>-->
<!--                        <b-td  class="text-center">-->
<!--                          <b-icon icon="clock-history" variant="warning"/> En Espera-->
<!--                        </b-td>-->
<!--                      </b-tr>-->
<!--                    </b-tbody>-->
<!--                  </b-table-simple>-->
<!--                </div>-->
<!--              </div>-->
<!--            </a-tab-pane>-->
<!--            <a-tab-pane :disabled="!showOtherTabs" key="3">-->
<!--              <div slot="tab">-->
<!--                <div class="container">-->
<!--                  <small class="text-center text-block text-white bg-primary rounded px-2 py-1">Descarga de materiales</small>-->
<!--                  <br>-->
<!--                  <a-progress :percent="5" status="active" size="small" strokeColor="#1890FF"/>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="card">-->
<!--                <div class="container">-->
<!--                  <b-table-simple hover stacked="xl" responsive="lg">-->
<!--                    <b-thead head-variant="light">-->
<!--                      <b-tr>-->
<!--                        <b-th  class="text-center"><small>Etapa</small></b-th>-->
<!--                        <b-th  class="text-center"><small>Progreso</small></b-th>-->
<!--                        <b-th  class="text-center"><small>Estatus</small></b-th>-->
<!--                      </b-tr>-->
<!--                    </b-thead>-->
<!--                    <b-tbody>-->
<!--                      <b-tr>-->
<!--                        <b-td  class="text-center">Inspección del residuo </b-td>-->
<!--                        <b-td  class="text-center">-->
<!--                          <a-progress :percent="0" size="small" status="active" />-->
<!--                        </b-td>-->
<!--                        <b-td  class="text-center">-->
<!--                          <b-icon icon="clock-history" variant="warning"/> En Espera-->
<!--                        </b-td>-->
<!--                      </b-tr>-->
<!--                      <b-tr>-->
<!--                        <b-td  class="text-center">Inspección de Carga </b-td>-->
<!--                        <b-td  class="text-center">-->
<!--                          <a-progress :percent="0" size="small" status="active" />-->
<!--                        </b-td>-->
<!--                        <b-td  class="text-center">-->
<!--                          <b-icon icon="clock-history" variant="warning"/> En Espera-->
<!--                        </b-td>-->
<!--                      </b-tr>-->
<!--                      <b-tr>-->
<!--                        <b-td  class="text-center">Clasificación de Materiales </b-td>-->
<!--                        <b-td  class="text-center">-->
<!--                          <a-progress :percent="0" size="small" status="active" />-->
<!--                        </b-td>-->
<!--                        <b-td  class="text-center">-->
<!--                          <b-icon icon="clock-history" variant="warning"/> En Espera-->
<!--                        </b-td>-->
<!--                      </b-tr>-->
<!--                    </b-tbody>-->
<!--                  </b-table-simple>-->
<!--                </div>-->
<!--              </div>-->
<!--            </a-tab-pane>-->
<!--            <a-tab-pane :disabled="!showOtherTabs" key="4">-->
<!--              <div slot="tab">-->
<!--                <div class="container">-->
<!--                  <small class="text-center text-block text-white bg-primary rounded px-2 py-1">Contabilización del servicio</small>-->
<!--                  <br>-->
<!--                  <a-progress :percent="0" status="active" size="small" strokeColor="#1890FF"/>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="card">-->
<!--                <div class="container">-->
<!--                  <b-table-simple hover stacked="xl" responsive="lg">-->
<!--                    <b-thead head-variant="light">-->
<!--                      <b-tr>-->
<!--                        <b-th  class="text-center"><small>Etapa</small></b-th>-->
<!--                        <b-th  class="text-center"><small>Progreso</small></b-th>-->
<!--                        <b-th  class="text-center"><small>Estatus</small></b-th>-->
<!--                      </b-tr>-->
<!--                    </b-thead>-->
<!--                    <b-tbody>-->
<!--                      <b-tr>-->
<!--                        <b-td  class="text-center">Visualización para Contabilización </b-td>-->
<!--                        <b-td  class="text-center">-->
<!--                          <a-progress :percent="0" size="small" status="active" />-->
<!--                        </b-td>-->
<!--                        <b-td  class="text-center">-->
<!--                          <b-icon icon="clock-history" variant="warning"/> En Espera-->
<!--                        </b-td>-->
<!--                      </b-tr>-->
<!--                      <b-tr>-->
<!--                        <b-td  class="text-center">Visualización para Facturación </b-td>-->
<!--                        <b-td  class="text-center">-->
<!--                          <a-progress :percent="0" size="small" status="active" />-->
<!--                        </b-td>-->
<!--                        <b-td  class="text-center">-->
<!--                          <b-icon icon="clock-history" variant="warning"/> En Espera-->
<!--                        </b-td>-->
<!--                      </b-tr>-->
<!--                    </b-tbody>-->
<!--                  </b-table-simple>-->
<!--                </div>-->
<!--              </div>-->
<!--            </a-tab-pane>-->
<!--          </a-tabs>-->
        </div>
      </div>
      <!--Porcentaje de avances -->
    </b-container>
    <template v-slot:modal-footer>
      <simple-modal-footer @ClickEvent="closeModal"/>
    </template>

  </b-modal>
</div>
</template>

<script>
import spCarrierExcessPermanenceForm from './spCarrierExcessPermanenceForm'

export default {
  name: 'serviceDetailModal',
  props: {
    serviceId: {
      default: null,
      type: [String],
      required: true,
    },
    buttonType: {
      default: 'table',
      type: [String],
      required: false,
    },
    showTable: {
      default: true,
      type: [Boolean],
    },
    defaultActiveKey: {
      default: '1',
      type: [String],
    },
    showOtherTabs: {
      default: true,
      type: [Boolean],
    },
  },
  components: {
    spCarrierExcessPermanenceForm,
  },
  data() {
    return {
      isBusy: false,
      columns: [
        { key: 'show_details', label: 'Más', class: 'text-center' },
        { key: 'lastSubstage', label: 'Etapa', class: 'text-center' },
        { key: 'sap_programming_code', label: 'SAP', class: 'text-center' },
        { key: 'workCenters', label: 'Planta', class: 'text-center' },
        { key: 'servicePlanningsPeriods', label: 'Fecha y Hora de Programación', class: 'text-center' },
        { key: 'serviceTypes', label: 'Tipo de Servicio', class: 'text-center' },
        { key: 'lastSubstage', label: 'Etapa del Servicio', class: 'text-center' },
        { key: 'service_planning_status', label: 'Estatus', class: 'text-center' },
        { key: 'excessPermanence', label: 'Exceso De permanencia', class: 'text-center' },
        { key: 'actions', label: 'Acciones', class: 'text-center' },
      ],
      materialColumns: [
        { key: 'currents', label: 'Corriente', class: 'text-center' },
        { key: 'containerTypes', label: 'Tipo de Contenedor', class: 'text-center' },
        { key: 'container_capacity', label: 'Capacidad del Contenedor', class: 'text-center' },
        { key: 'container_quantity', label: 'Cantidad de Contenedores', class: 'text-center' },
        { key: 'total', label: 'Cantidad Total', class: 'text-center' },
        { key: 'ums', label: 'Unidad de Medida', class: 'text-center' },
        { key: 'disposalTypes', label: 'Tipo De Disposición', class: 'text-center' },
      ],
      items: [],
      service: {},
    }
  },
  methods: {
    // Modal
    async openModal() {
      this.$refs['modal-Info'].show()
      await this.getServiceInfo()
    },
    closeModal() {
      this.$refs['modal-Info'].hide()
    },
    // Modal
    // Funciones que consumen informacion
    async getServiceInfo() {
      this.items = []

      const resourceId = 'servicePlannings/' + this.serviceId
      this.isBusy = true
      await this.$store.dispatch('jv/get', [resourceId, {
        params: {
          include: 'workCenters,servicePlanningsMaterials.currents,servicePlanningsMaterials.containerTypes,servicePlanningsMaterials.disposalTypes,servicePlanningsMaterials.ums,serviceTypes,servicePlanningsPeriods',
        },
      }])
        .then(response => {
          const objectResponse = response
          const objectTable = {
            sap_programming_code: objectResponse.sap_programming_code,
            material_guards_check: objectResponse.material_guards_check,
            service_planning_status: objectResponse.service_planning_status,
            sap_status: objectResponse.sap_status,
            status: objectResponse.status,
            own: objectResponse.own,
            lastSubstage: objectResponse.lastSubstage,
            excessPermanence: objectResponse.excessPermanence,
            // Relationships
            workCenters: objectResponse.workCenters,
            servicePlanningsMaterials: objectResponse.servicePlanningsMaterials,
            serviceTypes: objectResponse.serviceTypes,
            servicePlanningsPeriods: objectResponse.servicePlanningsPeriods,
          }
          this.items.push(objectTable)
          this.isBusy = false
        })
        .catch(error => {
          console.error(error)
          this.isBusy = false
        })
    },
    // Funciones que consumen informacion
    // Helpers
    fromObjectToArray(data) {
      return Object.keys(data).map(key => {
        return data[key]
      })
    },
    GoToServicePlanning(data) {
      const { item } = data
      this.$store.dispatch('servicePlannings/UPDATE_FOLIO', { payload: item.sap_programming_code })
      this.$store.dispatch('servicePlannings/UPDATE_KEY', { payload: item.sap_programming_code })
      this.$router.push({ name: 'service', params: { folio: item.sap_programming_code, id: item.sap_programming_code } })
    },
    // Helpers
  },
}
</script>

<style scoped>

</style>
